import sharedConfig from "../../../shared/shared-config";
import baseAPI from "../../../shared/services/base-api-service"
import {RecommendedLocation} from "../models/RecommendedLocation.model";
import {RecommendedExplore} from "../models/RecommendedExplore.model";
import {ThemeCollection, ThemeItem} from "../../../shared/models/Discover.model";

const state = {
    explore: [],
    locations: [],
    discover: [],
    isLoadingRecommendations: false,
    isLoadingDiscover: false,
    hasLoadedRecommendations: false,
    hasLoadedDiscover: false,
}

const getters = {
    getDiscover(state) {
        return state.discover;
    },
    isLoadingDiscover(state) {
        return state.isLoadingDiscover;
    },
    hasLoadedDiscover(state) {
        return state.hasLoadedDiscover;
    },
    getRecommendedExplore(state) {
        return state.explore;
    },
    getRecommendedLocations(state) {
        return state.locations;
    },
    isLoadingRecommendations(state) {
        return state.isLoadingRecommendations;
    },
    hasLoadedRecommendations(state) {
        return state.hasLoadedRecommendations;
    }
}

const actions = {
    [sharedConfig.store.actions.STATIC.POST_DISCOVER]:  ({commit, dispatch}, data) => {
        console.log(data);
        return new Promise(async (resolve, reject) => {
            baseAPI
                .postData(`${sharedConfig.api.discoverEndpoint}`, data)
                .then(resp => {
                    resolve();
                })
                .catch(e => {
                    reject(e);
                });

        });
    },
    [sharedConfig.store.actions.STATIC.PUT_DISCOVER]:  ({commit, dispatch}, data) => {
        console.log(data);
        return new Promise(async (resolve, reject) => {
            baseAPI
                .putData(`${sharedConfig.api.discoverEndpoint}/${data.id}`, data.data)
                .then(resp => {
                    resolve();
                })
                .catch(e => {
                    reject(e);
                });

        });
    },
    [sharedConfig.store.actions.STATIC.GET_RECOMMENDED_REQUEST]: ({commit, dispatch}) => {
        commit(sharedConfig.store.actions.STATIC.GET_RECOMMENDED_REQUEST);
        return new Promise(async (resolve, reject) => {
            baseAPI
                .getData(`${sharedConfig.api.recommendationEndpoint}`)
                .then(resp => {
                    commit(sharedConfig.store.actions.STATIC.GET_RECOMMENDED_SUCCESS, resp.data);
                    resolve();
                })
                .catch(e => {
                    commit(sharedConfig.store.actions.STATIC.GET_RECOMMENDED_ERROR, e);
                    reject(e);
                });

        });
    },

    [sharedConfig.store.actions.STATIC.GET_DISCOVER_REQUEST]: ({ dispatch, commit, getters, rootGetters }) => {
        commit(sharedConfig.store.actions.STATIC.GET_DISCOVER_REQUEST);
        return new Promise(async (resolve, reject) => {
            baseAPI
                .getData(`${sharedConfig.api.discoverEndpoint}/admin`)
                .then(resp => {
                    commit(sharedConfig.store.actions.STATIC.GET_DISCOVER_SUCCESS, {data: resp.data, spots: rootGetters.getStoryspots});
                    resolve();
                })
                .catch(e => {
                    commit(sharedConfig.store.actions.STATIC.GET_DISCOVER_ERROR, e);
                    reject(e);
                });

        });
    },
}

const mutations = {
    [sharedConfig.store.actions.STATIC.GET_RECOMMENDED_REQUEST]: (state, error) => {
        // TODO. manage error response
        state.isLoadingRecommendations = true;
    },
    [sharedConfig.store.actions.STATIC.GET_RECOMMENDED_SUCCESS]: (state, data) => {
        state.explore = data['topics'].map((a) => new RecommendedExplore(a));
        state.locations = data['locations'].map((a) => new RecommendedLocation(a));
        state.hasLoadedRecommendations = true;
        state.isLoadingRecommendations = false;
    },
    [sharedConfig.store.actions.STATIC.GET_RECOMMENDED_ERROR]: (state, error) => {
        // TODO. manage error response
        state.isLoadingRecommendations = false;
    },

    [sharedConfig.store.actions.STATIC.GET_DISCOVER_REQUEST]: (state, error) => {
        // TODO. manage error response
        state.isLoadingDiscover = true;
    },
    [sharedConfig.store.actions.STATIC.GET_DISCOVER_SUCCESS]: (state, {data, spots}) => {
        let res = [];
        if (data != null) {
            for (let x = 0; x < data.length; x++) {
                let storyspots, collections, storyspot;
                if (data[x]?.storyspots) {
                    storyspots = data[x].storyspots.map((b) => spots[spots.map((sp) => sp.id).indexOf(b)]);
                } else if (data[x]?.collection) {
                    collections = data[x]['collection'].map((b) => {
                        const storyspotsList = (b['storyspots'] || []).map((c) => spots[spots.map((sp) => sp.id).indexOf(c)]);
                        return new ThemeItem({title: b['title'], image: b['image']}, storyspotsList);
                    });
                } else if (data[x]?.storyspot) {
                    storyspot = spots[spots.map((sp) => sp.id).indexOf(data[x].storyspot)];
                }
                const theme = new ThemeCollection(data[x], {col: collections, sps: storyspots, sp: storyspot});
               res.push(theme);
            }
        }
        res = res.sort((a,b) => a.sort - b.sort);
        state.discover = res;
        state.hasLoadedDiscover = true;
        state.isLoadingDiscover = false;
    },
    [sharedConfig.store.actions.STATIC.GET_DISCOVER_ERROR]: (state, error) => {
        // TODO. manage error response
        state.isLoadingDiscover = false;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
};