<template>
  <div>
    <div id="material-kit">
      <!--    <div :class="{ 'nav-open': NavbarStore.showNavbar }">
            <router-view name="header"/>
            <div style="height: 100px"/>
            <router-view/>
            <router-view name="footer"/>
          </div> -->
      <div class="page-container">
        <md-app md-waterfall md-mode="fixed" style="height: 100vh !important;">
          <md-app-toolbar class="md-primary">
            <router-link to="/">
              <div class="avatar">
                <img
                    src="./assets/logo_white_icon.png"
                    alt="Logo"
                    width="60"
                    height="60"
                    class="img-fluid"
                />
              </div>
            </router-link>
          </md-app-toolbar>

          <md-app-drawer md-permanent="clipped" v-if="isAdmin">
            <!--  <md-toolbar class="md-transparent" md-elevation="0">
                Navigation
              </md-toolbar> -->

            <div class="md-collapse">
              <div class="md-collapse-wrapper">
                <md-list>
                  <DatePickerIcon/>
                  <md-list-item to="/dashboard">
                    <p class="menu-text">Dashboard</p>
                    <md-icon>dashboard</md-icon>
                  </md-list-item>

                  <md-list-item to="/storyspots">
                    <p class="menu-text">Storyspots</p>
                    <md-icon>explore</md-icon>
                  </md-list-item>

                  <md-list-item to="/users">
                    <p class="menu-text">Users</p>
                    <md-icon>people</md-icon>
                  </md-list-item>

                  <md-list-item to="/organizations">
                    <p class="menu-text">Organizations</p>
                    <md-icon>diversity_3</md-icon>
                  </md-list-item>


                  <md-divider></md-divider>
                  <md-subheader>Recommendations</md-subheader>

                  <md-list-item to="/recommendations/explore">
                    <p class="menu-text">Explore</p>
                    <md-icon>travel_explore</md-icon>
                  </md-list-item>


                  <md-list-item to="/recommendations/discover">
                    <p class="menu-text">Discover</p>
                    <md-icon>travel_explore</md-icon>
                  </md-list-item>


                  <md-divider></md-divider>
                  <md-subheader>Static data</md-subheader>

                  <md-list-item to="/categories">
                    <p class="menu-text">Categories</p>
                    <md-icon>category</md-icon>
                  </md-list-item>

                  <md-list-item to="/languages">
                    <p class="menu-text">Languages</p>
                    <md-icon>language</md-icon>
                  </md-list-item>

                  <md-list-item to="/geofence">
                    <p class="menu-text">Geofences</p>
                    <md-icon>where_to_vote</md-icon>
                  </md-list-item>

                  <md-list-item to="/reports">
                    <p class="menu-text">Reports</p>
                    <md-icon>report</md-icon>
                  </md-list-item>

                  <md-list-item to="/storyspotters">
                    <p class="menu-text">Storyspotters</p>
                    <md-icon>people</md-icon>
                  </md-list-item>

                  <md-list-item to="/files">
                    <p class="menu-text">Files</p>
                    <md-icon>folder</md-icon>
                  </md-list-item>


                  <md-divider></md-divider>
                  <md-subheader>Other portals</md-subheader>

                  <md-list-item href="https://app.storyspot.se">
                    <p class="menu-text">User portal</p>
                    <md-icon>house</md-icon>
                  </md-list-item>

                  <md-list-item v-if="hasOrganization" href="https://org.storyspot.se">
                    <p class="menu-text">Organization portal</p>
                    <md-icon>business</md-icon>
                  </md-list-item>


                  <md-list-item @click="logout" href="https://admin-beta.storyspot.se">
                    <p class="menu-text">Sign out</p>
                    <md-icon>logout</md-icon>
                  </md-list-item>


                </md-list>
              </div>
            </div>
          </md-app-drawer>

          <md-app-content>
            <router-view/>
            <AudioPlayer class="audio-player on-top-player"/>
          </md-app-content>
        </md-app>
        <modal v-if="showModal" @close="closeModal">
          <template slot="header">
            <h1 class="modal-title">Disclaimer</h1>
            <md-button
                class="md-simple md-just-icon md-round modal-default-button"
                @click="closeModal"
            >
              <md-icon>clear</md-icon>
            </md-button>
          </template>

          <template slot="body">
            <p>This Admin portal in in <strong>early Beta.</strong> and is subject to continiues changes</p>
            <br>
            <p>All functionality may not be in place, and issues and bugs may be present.
            </p>
            <br>
            <br>
          </template>

        </modal>
        <DatePicker/>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from "../../shared/components/Modal";
import sharedConfig from "../../shared/shared-config"
import AudioPlayer from "../../shared/components/AudioPlayer";
import sessionHandler from "../../shared/utils/session-handler";
import DatePicker from "./components/DatePicker/DatePicker";
import DatePickerIcon from "./components/DatePicker/DatePickerIcon";

export default {
  name: 'App',
  components: {
    DatePicker,
    DatePickerIcon,
    AudioPlayer,
    Modal
  },
  data() {
    return {
      showModal: false
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    hasOrganization() {
      return this.$store.getters.isPartOfOrganization;
    },
  },
  mounted() {
    if (!window.location.href.indexOf('/login') > -1) {
      if (this.$store.getters.isAuthenticated && this.$store.getters.isAdmin) {
        if (!sessionHandler.get(sharedConfig.session.disclaimer)) {
          this.$store.dispatch(sharedConfig.store.actions.AUTH.GET_PROFILE);
        }
        if (!this.$store.getters.hasLoadedStoryspots || !this.$store.getters.hasLoadedUsers) {
          Promise.all([this.$store.dispatch(sharedConfig.store.actions.STATIC.GET_STATIC_DATA_REQUEST), this.$store.dispatch(sharedConfig.store.actions.ADMIN.GET_ALL_STORYSPOTS_REQUEST), this.$store.dispatch(sharedConfig.store.actions.ADMIN.GET_ALL_USERS_REQUESTS)]).then(() => {
          }).catch((e) => {
          })
        }
        if (!this.$store.getters.hasLoadedRecommendations) {
          this.$store.dispatch(sharedConfig.store.actions.STATIC.GET_RECOMMENDED_REQUEST);
        }
        if (!this.$store.getters.hasLoadedReports) {
          this.$store.dispatch(sharedConfig.store.actions.ADMIN.GET_STORYSPOTTERS_REQUESTS);
        }
      }
      if (!sessionHandler.get(sharedConfig.session.disclaimer)) {
        sessionHandler.set(sharedConfig.session.disclaimer, new Date());
      }
    }
  },
  methods: {
    displayModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    logout() {
      this.$store.dispatch(sharedConfig.store.actions.AUTH.LOGOUT_REQUEST);
    },
  }
}
</script>
<style lang="scss">
.md-toolbar {
  border-radius: 0px !important;
  margin-bottom: 0 !important;
}

.md-drawer.md-theme-default {
  width: 300px;
}

.md-list-item:hover {
  p {
    color: #0917E3;
  }
}

.menu-text {
  font-weight: bold !important;
}

.main-content {
  margin-top: 60px;
}


.on-top-player {
  position: fixed;
  bottom: 25px;
  right: 25px;
  height: 60px;
  margin: 0;
  padding: 0;
  z-index: 1;
}

@media screen and (max-width: 1024px) {
  .on-top-player {
    max-width: 400px;
  }
}

@media screen and (max-width: 480px) {
  .on-top-player {
    width: 100%;
  }
}
</style>
