<template>
  <div>
    <div class="file-container" @click="onPress">
      <div class="file-info">
        <md-icon>{{ getIcon() }}</md-icon>
        <p class="filename">{{ file.displayName }}</p>
      </div>
      <div class="file-actions">
        <p v-if="!file.isFolder">{{ sizeToString(file.size) }}b</p>
        <md-icon v-if="!file.isFolder">download</md-icon>
      </div>
    </div>
    <hr>
  </div>
</template>
<script>
import HTTP from "../services/axios-default";

export default {
  components: {},
  props: {
    file: {
      type: Object,
    },
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {
    downloadItem() {
      HTTP.get('organization/storage/' + this.file.id, {responseType: 'blob'})
          .then(response => {
            const blob = new Blob([response.data], {type: this.file.contentType})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = this.file.name;
            link.click()
            URL.revokeObjectURL(link.href)
          }).catch(console.error)
    },
    onPress() {
      if (this.file.isFolder) {
        this.$emit('did-press', this.file.directory + this.file.name + '/');
      } else {
        this.downloadItem(this.file);
      }
    },
    getIcon() {
      if (this.file && this.file.isFolder) {
        return 'folder';
      }
      if (this.file && this.file.contentType) {
        if (this.file.contentType.startsWith('image')) {
          return 'image'
        } else if (this.file.contentType.startsWith('folder')) {
          return 'folder';
        } else {
          return 'article';
        }
      }
    },
    sizeToString(size) {
      try {
        size = parseInt(size);
        if (size > 1000000000) {
          return (size / 1000000000).toFixed(1) + " g";
        }
        if (size > 1000000) {
          return (size / 1000000).toFixed(1) + " m";
        }
        if (size > 1000) {
          return (size / 1000).toFixed(1) + " k";
        }
        return size.toFixed(1) + " b";
      } catch(e) {
        return '';
      }
    },
  },
  computed: {},

}
</script>
<style lang="css">
.file-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.file-info {
  display: flex;
  align-items: center;
}
.file-info .md-icon {
  margin-right: 8px;
}
.file-actions {
  display: flex;
  align-items: center;
}
.file-actions .md-icon {
  margin-left: 8px;
}
p {
  font-family: 'Inter';
}
.file-container .file-info .filename {
  margin: auto;
}

.file-container .file-info .foldername {
  margin: auto;
  font-weight: bold;
}
</style>