<template>
  <div>
  </div>
</template>
<script>


export default {
  components: {},
  props: {
    storyspot: {
      type: Object,
      default: null
    },
  },
  data() {
    return {}
  },
}
</script>
<style>

</style>