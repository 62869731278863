<template>
  <div>
    <div class="main-content">
      <div class="wrapper">
        <div class="main main-raised">
          <div class="section">
            <div v-if="loading" class="loader md-layout md-gutter md-alignment-center-center">
              <md-progress-spinner value="center" md-mode="indeterminate"></md-progress-spinner>
            </div>
            <div v-if="!loading" class="container">
              <div class="header">
                <h1>
                  Discover
                </h1>
              </div>
              <div class="md-layout">
              <!-- CREATE STORYSPOT -->
              <div v-if="discoverType === 'storyspot'">
                <div class="md-layout-item md-size-100">
                  <md-field>
                    <md-icon>title</md-icon>
                    <label>Title</label>
                    <md-input v-model="discover.title" type="text"></md-input>
                  </md-field>
                  <div class="scrollmenu text-left md-layout">
                    <a v-for="spot in discover.storyspots">
                      <div class="scrollmenu-div">
                        <div class="image-container">
                          <img :src="spot.displayImage" alt="Rounded Image" class="displayImage scroll-img"/>
                          <div class="info">
                            <md-icon class="icon">play_arrow</md-icon>
                            <p class="short-text">{{ spot.durationTime }}</p>
                          </div>
                        </div>
                        <p class="scrollmenu-text">{{ spot.name }}</p>
                      </div>
                    </a>
                  </div>
                  <p v-if="discover.storyspots === null">Select storyspots</p>
                  <div class="text-right">
                    <md-button class="md-primary btn-regular" @click="toggleStoryspotModal">
                      <md-icon>add</md-icon>
                      Select storyspots
                    </md-button>
                  </div>
                </div>
                <div class="md-layout-item md-size-100">
                  <md-field>
                    <md-icon>sort</md-icon>
                    <label>Sort order</label>
                    <md-input v-model="discover.sort" type="number"></md-input>
                  </md-field>
                  <md-field>
                    <md-icon>schedule</md-icon>
                    <label>Start date</label>
                    <md-input v-model="discover.startDate" type="date"></md-input>
                  </md-field>
                  <md-field>
                    <md-icon>schedule</md-icon>
                    <label>End date</label>
                    <md-input v-model="discover.endDate" type="date"></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-100 m-top text-center">
                  <md-button class="md-primary md-lg" @click="editDiscover" :disabled="!storyspotValid">Create
                  </md-button>
                </div>
              </div>
              <!-- END CREATE STORYSPOT -->
              <div v-if="discoverType !== 'storyspot'">
                <p class="text-center">WORK IN PROGRESS</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
    <modal v-if="showStoryspotModal" @close="toggleStoryspotModal">
      <template slot="header">
        <h1 class="modal-title">Add storyspots</h1>
        <md-button
            class="md-simple md-just-icon md-round modal-default-button"
            @click="toggleStoryspotModal"
        >
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot="body">
        <SearchStoryspotModal @add-storyspots="addStoryspots" :close="toggleStoryspotModal"/>
      </template>

    </modal>
  </div>
</template>

<script>


import Autocomplete from "../../../../shared/components/Autocomplete";
import Badge from "../../../../shared/components/Badge";
import Modal from "../../../../shared/components/Modal";
import SearchStoryspotModal from "../../../../shared/components/SearchStoryspotModal";
import sharedConfig from "../../../../shared/shared-config";
import {getStringDate} from "../../../../shared/utils/general-utils";
import router from "../../router";

export default {
  bodyClass: "Create-discover",
  components: {
    Autocomplete,
    Badge,
    Modal,
    SearchStoryspotModal
  },
  created() {
    document.title = "Discover - Admin panel";
  },
  data() {
    return {
      discoverId: null,
      loading: true,
      discoverType: null,
      types: [
        {id: 'storyspot', name: 'Storyspots'},
        {id: 'collection', name: 'Collection of storyspots'},
        {id: 'singleWideStoryspots', name: 'Single wide storyspot'},
        {id: 'singleStoryspot', name: 'Storyspot recommendation'},
      ],
      discover: {
        title: null,
        storyspots: null,
        sort: null,
        startDate: null,
        endDate: null,
      },
      showStoryspotModal: false,
    };
  },
  mounted() {
    this.discoverId = this.$route.params.id;
    if (this.$store.getters.hasLoadedDiscover) {
      if (this.$store.getters.getDiscover && this.$store.getters.getDiscover.filter((a) => a.id === this.discoverId).length > 0) {
        let disc = this.$store.getters.getDiscover.filter((a) => a.id === this.discoverId)[0];
        this.discoverType = disc.type;
        this.discover.title = disc.title;
        this.discover.storyspots = disc._list;
        this.discover.sort = disc.sort;
        if (disc.startDate) {
          let sDate = new Date(disc.startDate);
          this.discover.startDate = getStringDate(sDate);
        }
        if (disc.endDate) {
          let sDate = new Date(disc.endDate);
          this.discover.endDate = getStringDate(sDate);
        }
        this.loading = false;
      } else {
        this.$store.dispatch(sharedConfig.store.actions.STATIC.GET_DISCOVER_REQUEST).then((a) => {
          if (this.$store.getters.getDiscover && this.$store.getters.getDiscover.filter((a) => a.id === this.discoverId).length > 0) {
            let disc = this.$store.getters.getDiscover.filter((a) => a.id === this.discoverId)[0];
            this.discoverType = disc.type;
            this.discover.title = disc.title;
            this.discover.storyspots = disc._list;
            this.discover.sort = disc.sort;
            if (disc.startDate) {
              let sDate = new Date(disc.startDate);
              this.discover.startDate = getStringDate(sDate);
            }
            if (disc.endDate) {
              let sDate = new Date(disc.endDate);
              this.discover.endDate = getStringDate(sDate);
            }
            this.loading = false;
          } else {
            console.log('could not find discover');
            this.loading = false;
          }
        })
      }
    }
  },
  methods: {
    toggleStoryspotModal() {
      this.showStoryspotModal = !this.showStoryspotModal;
    },
    addStoryspots(data) {
      this.discover.storyspots = data;
    },
    editDiscover() {
      let postData = JSON.parse(JSON.stringify(this.discover));
      if (postData.storyspots) {
        postData.storyspots = postData.storyspots.map((a) => a.id);
      }
      this.$store.dispatch(sharedConfig.store.actions.STATIC.PUT_DISCOVER, {id: this.discoverId, data: postData}).then((a) => {
        router.replace('/recommendations/discover');
      }).catch((e) => {
        console.log(e);
      })
    }
  },
  computed: {
    storyspotValid() {
      if (this.discover.title == null || this.discover.title.length < 5) {
        return false;
      }
      if (this.discover.storyspots == null || this.discover.storyspots.length === 0) {
        return false;
      }
      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
.second {
  margin-top: 120px;
}

.add-user-container {
  margin-left: auto;
  margin-right: 0;
}

.top-pad {
  margin-top: 45px;
}

.header {
  margin-bottom: 44px;
}


.image-button-container .md-button {
  width: 100%;
  max-width: 200px;
}

.details {
  margin-top: 45px;
}

textarea {
  display: block;
  padding-left: 0 !important;
  resize: none !important;
}


.section {
  // Used cause we want the create storyspot and search header to act as section "headers"
  padding-top: 20px !important;
}

.main {
  min-height: 80vh;
  z-index: 0;
}

.button-container {
  margin: auto;
  margin-top: 44px;
}

.md-textarea {
  padding-left: 22px !important;
}

.md-field.md-has-textarea:not(.md-autogrow) > .md-icon {
  right: unset;
}

.label-textarea {
  margin-left: 33px;
}

.md-field + .md-has-textarea:not(.md-autogrow) {
  margin-top: 0 !important;
}

.scrollmenu {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
}

scrollmenu a {
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

.scrollmenu-div {
  flex: 0 0 180px; /* Set a fixed width for each item */
  display: flex; /* Use flexbox for vertical alignment of image and text */
  flex-direction: column; /* Stack image and text vertically */
  align-items: center; /* Center items horizontally */
  margin-right: 10px; /* Add some spacing between items */
  flex-wrap: nowrap; /* Prevent flex items from wrapping to the next line */
  position: relative;
}

.scrollmenu-text {
  width: 162px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.scroll-img {
  margin-left: 0 !important;
  height: 150px;
  width: 150px;
  object-fit: cover;
  border-radius: 8px;
}


.image-container {
  position: relative; /* Set the container as a reference for absolute positioning */
}

.icon {
  width: 20px;
  height: 20px;
  background-color: white; /* Set the background color or use an image for the icon */
  margin-right: 5px;
  position: absolute;
  bottom: 5px; /* Adjust the distance from the bottom as needed */
  left: 5px; /* Adjust the distance from the left as needed */
  border-radius: 10px;
}

.info {
  position: absolute; /* Position the .info div absolutely */
  bottom: 5px; /* Adjust the distance from the bottom as needed */
  left: 5px; /* Adjust the distance from the left as needed */
  display: flex; /* Use flexbox to align icon and text horizontally */
  align-items: center; /* Center icon and text vertically */

}

.short-text {
  margin: 0; /* Remove default margin */
  white-space: nowrap; /* Prevent the text from wrapping */
  overflow: hidden;
  text-overflow: ellipsis; /* Add ellipsis if the text overflows */
  position: absolute;
  bottom: 5px; /* Adjust the distance from the bottom as needed */
  left: 35px; /* Adjust the distance from the left as needed */
  max-width: 120px;
  color: white;
  font-weight: bold;
}
</style>
