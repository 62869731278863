<template>
  <div>
    <md-field class="md-form-group">
      <md-icon>search</md-icon>
      <md-input
          v-model="searchText"
          placeholder="Select user"
      ></md-input>
    </md-field>
    <div>
      <div class="user-container" v-for="usr in getUsers" v-bind:key="usr.id">
        <div>
          <md-button class="md-mini md-primary"  @click="selectedUser(usr)">{{usr.email}}</md-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  components: {},
  prop: {
    add: { type: Function },
  },
  data() {
    return {
      searchText: null,
      selected: false,
      selectedUsers: [],
    };
  },
  methods: {
    selectedUser(user) {
      this.searchText = user.email;
      setTimeout(() => this.selected = true, 50);
      this.$emit('selected-user', user);
    },
  },
  watch: {
    'searchText': function (val, oldVal) {
      this.selected = false;
    },
  },
  computed: {
    getUsers() {
      if (this.searchText && !this.selected) {
        return this.$store.getters.getUsers.filter(
            (a) =>
                a.q && a.q.indexOf(this.searchText.toLowerCase()) > -1
        ).slice(0,8);
      }
      return [];
    }
  }
};
</script>
<style scoped>
.small-paragraph {
  margin-top: 26px;
}
.user-container {
  margin-top:auto;
  margin-bottom:auto;
  text-align:left;
}

.add-user-container {
  margin-left: auto;
  margin-right: 0;
}
</style>