<template>
  <div class="wrapper">
      <div class="section">
        <div class="container">
          <div v-if="isLoading" class="loader md-layout md-gutter md-alignment-center-center">
            <md-progress-spinner value="center" md-mode="indeterminate"></md-progress-spinner>
          </div>
          <div v-if="!isLoading" class="container">
            <md-switch v-model="showAsTable" class="md-primary">Show as table</md-switch>
            <SearchBar class="top-padding" @send-searchparameter="didChangeSearch" :title="'All storyspots'"
                       :storyspotCount="'(' + getStoryspots.length + ')'" :filter="true"/>
            <div v-if="showAsTable">
              <md-table v-model="pageOfItems" md-card>
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                  <md-table-cell md-label="Name" md-sort-by="name">
                     <router-link :to="'/storyspots/' + item.id">{{ item.name }}</router-link>
                  </md-table-cell>
                  <md-table-cell md-label="Author" md-sort-by="author.username">{{ item.author.username }}</md-table-cell>
                  <md-table-cell md-label="Listens" md-sort-by="listens">{{ item.listens }}</md-table-cell>
                  <md-table-cell md-label="Category" md-sort-by="category.name">
                    <badge :style="`background-color: ${item.category.color}`">
                      {{ item.category.name }}
                    </badge>
                  </md-table-cell>
                  <md-table-cell md-label="Status" md-sort-by="status">
                    <badge :style="`background-color: ${getColor(item)}`">
                      {{ item.status.name }}
                    </badge>
                  </md-table-cell>
                  <md-table-cell md-label="Created at" md-sort-by="createdAt">{{ item.currentDateString }}</md-table-cell>
                </md-table-row>
              </md-table>
            </div>
            <div v-else>
              <div v-for="story in pageOfItems" v-bind:key="story.id">
                <StoryspotSmall :sp="story" :toSpotBasePath="'/storyspots/'"/>
              </div>
            </div>
          </div>
          <div class="text-right">
            <jw-pagination :items="getStoryspots" @changePage="onChangePage" :pageSize="50"
                           :labels="customLabels"></jw-pagination>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import SearchBar from "../../../shared/components/SearchBar";
import StoryspotSmall from "../../../shared/components/cells/StoryspotCell";
import StoryspotMap from "../../../shared/components/StoryspotMap";
import sharedConfig from "../../../shared/shared-config";
import Badge from "../../../shared/components/Badge";

export default {
  bodyClass: "My-storyspots",
  components: {
    StoryspotSmall,
    SearchBar,
    StoryspotMap,
    Badge
  },
  created() {
    document.title = "All Storyspots - Storyspot";
  },
  mounted() {
    if (!this.$store.getters.hasLoadedStoryspots) {
      this.$store.dispatch(sharedConfig.store.actions.ADMIN.GET_ALL_STORYSPOTS_REQUEST);
    }
  },
  data() {
    return {
      searchText: null,
      pageOfItems: [],
      table: [],
      showAsTable: false,
      currentSort: 'name',
      currentSortOrder: 'asc',
      customLabels: {
        first: '<<',
        last: '>>',
        previous: '<',
        next: '>'
      },
    };
  },
  methods: {
    didChangeSearch(value) {
      this.searchText = value;
    },
    onChangePage(pageOfItems) {
      this.pageOfItems = pageOfItems;
    },
    customSort (value) {
      return value.sort((a, b) => {
        //console.log(value);
        const sortBy = this.currentSort

        if (this.currentSortOrder === 'desc') {
          //return a[sortBy].localeCompare(b[sortBy])
          return a.sort((a,b) => a[sortBy] - b[sortBy])
        }

        return a.sort((a,b) => b[sortBy] - a[sortBy])
      })
    },
    getColor(item) {
      if (item.status.name.toLowerCase() == 'active') {
        return 'green';
      } else if (item.status.name.toLowerCase() == 'deleted') {
        return 'red';
      } else {
        return 'blue';
      }
    }
  },
  computed: {
    getStoryspots() {
      if (this.searchText) {
        return this.$store.getters.getStoryspots.filter(
            (a) =>
                a.q && a.q.indexOf(this.searchText.toLowerCase()) > -1
        )
      }
      return this.$store.getters.getStoryspots
    },
    isLoading() {
      return this.$store.getters.isLoadingStoryspots;
    }
  }
};
</script>

<style lang="scss" scoped>
.top-padding {
  margin-top: 25px;
}

.section {
  // Used cause we want the create storyspot and search header to act as section "headers"
  padding-top: 20px !important;
}

.main {
  min-height: 80vh;
  z-index: 0;
}

.button-container {
  margin-left: auto;
  margin-right: 0;
}
</style>
