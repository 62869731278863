<template>
  <div>
    <div>
      <div class="md-layout md-gutter">
        <div
            class="md-layout-item md-size-20 md-xlarge-size-20 md-large-size-20 md-alignment-center-center"
        >
          <div class="no-profile-pic-container">
              <span class="dot" :style="`background-color: ${category.color}`"></span>
          </div>
        </div>
        <div
            class="md-layout-item md-size-70 user-text-container"
        >
          <div class="text-container">
            <div class="blockquote large-paragraph text-left">
              <small>
                {{ category.status.name }} <strong> • </strong>  Sorting order: {{ category.order }}
              </small>
            </div>
            <div class="text-left">
              <h4 class="title">
                <router-link :to="'/categories/' + category.id">
                  <span class="tim-note">{{ category.name }}</span>
                </router-link>
              </h4>
              <p class="large-paragraph">Current count {{counted}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <li class="dropdown-divider"></li>
  </div>
</template>
<script>
import Badge from "../Badge";

export default {
  components: {
    Badge
  },
  props: {
    category: {
      type: Object,
      default: null
    },

  },
  data() {
    return {
    }
  },
  computed: {
    counted() {
      return this.$store.getters.getStoryspots.filter((a) => a.category && a.category.id === this.category.id).length;
    }
  },
};
</script>
<style lang="scss" scoped>

.wrapper {
  margin-top: 28px;
}

.blockquote {
  font-size: 18px;
}

.blockquote strong {
  margin: 0 5px 0 5px;
}

.text-container {
  float: left;
  margin-top: 0;
  padding: 0;
}

.text-container .p {
  text-overflow: ellipsis;
}

.title {
  margin-top: 0 !important;
  margin-bottom: 5px !important;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;
  color: #3C4858 !important;
}

.md-theme-default a:not(.md-button) {
  color: unset !important;
}


.center {
  margin: auto;
  width: 50%;
}

.icon {
  font-size: 18px !important;
}

.text-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.play-icon {
  position: absolute !important;
  right: 30px;
}


.dropdown-item:hover {
  .md-icon {
    color: white !important;
  }

  p {
    color: white !important;
  }
}

.md-tooltip {
  border-radius: unset;
  color: white !important;
  background-color: #989898 !important;
  font-weight: unset;
  box-shadow: unset;
  max-width: unset;
}

.not-active {
  float: left;
  padding-right: 8px
}

.displayImage {
  object-fit: contain !important;
  // Just to fill width
  width: 100px;
}

.no-profile-pic-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.no-profile-pic {
  font-size: 68px !important;
  opacity: 0.5;
}

.md-list-item a .material-icons, .md-list-item a .md-icon, .md-list-item a .fab, .md-list-item a .fa {
  font-size: 25px !important;
}

.mini-list {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  p {
    padding-top: 10px;
  }
}

.user-text-container {
  min-height: 100px;

}
.dot {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: inline-block;
}
</style>