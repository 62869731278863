import Vue from "vue";
import Router from "vue-router";

import Storyspots from "./views/Storyspots.vue";
import Login from "./views/Login";
import StoryspotDetails from "./views/StoryspotDetails";
import Users from "./views/users/Users";
import User from "./views/users/User";
import Organizations from "./views/OrganizationList";
import Organization from "./views/Organization";
import CreateOrganization from "./views/organization/CreateOrganization";
import Categories from "./views/Static/CategoryList";
import Category from "./views/Static/Category";
import Languages from "./views/Static/LanguageList";
import ReportsList from "./views/Static/ReportsList";
import GeofenceList from "./views/Static/GeofenceList";
import Faq from "./views/Static/FAQ.vue";
import Navigation from "./components/MainNavigation";
import Footer from "../../shared/components/Footer";
import store from "./store/index";
import EditStoryspot from "./views/EditStoryspot";
import Dashboard from "./views/Dashboard";
import RecommendationExplore from "./views/recommendations/RecommendedTopicsList";
import RecommendationExploreDetails from "./views/recommendations/RecommendedTopics";
import RecommendationLocation from "./views/recommendations/RecommendedLocationsList";
import StoryspotterList from "./views/Static/StoryspottersList";
import ResetPassword from "./views/ResetPassword";
import DiscoverList from "./views/recommendations/DiscoverList";
import DiscoverCreate from "./views/recommendations/CreateDiscover";
import DiscoverEdit from "./views/recommendations/DiscoverEdit";
import Files from "./views/Files";

Vue.use(Router);


const ifAuthenticated = (to, from, next) => {
    if (store.getters.isAuthenticated && store.getters.isAdmin) {
        console.log('ifAuthenticated', true)
        next();
        return;
    }
    console.log('ifAuthenticated', false)
    next("/");
};

const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters.isAuthenticated || !store.getters.isAdmin) {
        console.log('ifNotAuthenticated', true)
        next();
        return;
    }
    console.log('ifNotAuthenticated', false)
    next("/dashboard");
};


export default new Router({
    mode: 'history',
    routes: [
        {
            path: "/",
            redirect: "/login",
            beforeEnter: ifNotAuthenticated,
            mode: 'history',
        },
        {
            path: "/login",
            name: "Login",
            beforeEnter: ifNotAuthenticated,
            components: { default: Login},
            props: { sidebar: false }
        },
        {
            path: "/dashboard",
            name: "Dashboard",
            beforeEnter: ifAuthenticated,
            components: { default: Dashboard, header: Navigation, footer: Footer },
        },
        {
            path: "/storyspots",
            name: "Storyspots",
            beforeEnter: ifAuthenticated,
            components: { default: Storyspots, header: Navigation, footer: Footer },
        },
        {
            path: "/storyspots/:id",
            name: "Storyspot",
            beforeEnter: ifAuthenticated,
            components: { default: StoryspotDetails, header: Navigation, footer: Footer },
        },
        {
            path: "/users",
            name: "Users",
            beforeEnter: ifAuthenticated,
            components: { default: Users, header: Navigation, footer: Footer },
        },
        {
            path: "/users/:id",
            name: "User",
            beforeEnter: ifAuthenticated,
            components: { default: User, header: Navigation, footer: Footer },
        },
        {
            path: "/organizations",
            name: "Organizations",
            beforeEnter: ifAuthenticated,
            components: { default: Organizations, header: Navigation, footer: Footer },
        },
        {
            path: "/organizations/create",
            name: "Organization-create",
            beforeEnter: ifAuthenticated,
            components: { default: CreateOrganization, header: Navigation, footer: Footer },
        },
        {
            path: "/organizations/:id",
            name: "Organization",
            beforeEnter: ifAuthenticated,
            components: { default: Organization, header: Navigation, footer: Footer },
        },
        {
            path: "/categories",
            name: "Categories",
            beforeEnter: ifAuthenticated,
            components: { default: Categories, header: Navigation, footer: Footer },
        },
        {
            path: "/categories/:id",
            name: "Category",
            beforeEnter: ifAuthenticated,
            components: { default: Category, header: Navigation, footer: Footer },
        },
        {
            path: "/languages",
            name: "Languages",
            beforeEnter: ifAuthenticated,
            components: { default: Languages, header: Navigation, footer: Footer },
        },
        {
            path: "/reports",
            name: "Reports",
            beforeEnter: ifAuthenticated,
            components: { default: ReportsList, header: Navigation, footer: Footer },
        },
        {
            path: "/storyspotters",
            name: "Storyspotters",
            beforeEnter: ifAuthenticated,
            components: { default: StoryspotterList, header: Navigation, footer: Footer },
        },
        {
            path: "/geofence",
            name: "Geofence",
            beforeEnter: ifAuthenticated,
            components: { default: GeofenceList, header: Navigation, footer: Footer },
        },
        {
            path: "/recommendations/explore",
            name: "ExploreList",
            beforeEnter: ifAuthenticated,
            components: { default: RecommendationExplore, header: Navigation, footer: Footer },
        },
        {
            path: "/recommendations/explore/:id",
            name: "Explore",
            beforeEnter: ifAuthenticated,
            components: { default: RecommendationExploreDetails, header: Navigation, footer: Footer },
        },
        {
            path: "/recommendations/locations",
            name: "Explore",
            beforeEnter: ifAuthenticated,
            components: { default: RecommendationLocation, header: Navigation, footer: Footer },
        },
        {
            path: "/recommendations/discover",
            name: "Discover",
            beforeEnter: ifAuthenticated,
            components: { default: DiscoverList, header: Navigation, footer: Footer },
        },
        {
            path: "/recommendations/discover/create/",
            name: "Discover",
            beforeEnter: ifAuthenticated,
            components: { default: DiscoverCreate, header: Navigation, footer: Footer },
        },
        {
            path: "/recommendations/discover/edit/:id",
            name: "Discover",
            beforeEnter: ifAuthenticated,
            components: { default: DiscoverEdit, header: Navigation, footer: Footer },
        },
        {
            path: "/edit-storyspot/:id",
            name: "EditStoryspot",
            beforeEnter: ifAuthenticated,
            components: { default: EditStoryspot, header: Navigation, footer: Footer },
        },
        {
            path: "/reset-password/:id",
            name: "Reset password",
            components: { default: ResetPassword, header: Navigation, footer: Footer },
        },
        {
            path: "/files",
            name: "Files",
            beforeEnter: ifAuthenticated,
            components: { default: Files, header: Navigation, footer: Footer },
        },
        {
            path: "/files/*",
            name: "Files",
            beforeEnter: ifAuthenticated,
            components: { default: Files, header: Navigation, footer: Footer },
        },
        {
            path: "/faq",
            name: "FAQ",
            beforeEnter: ifAuthenticated,
            components: { default: Faq, header: Navigation, footer: Footer },
        },
    ],
    scrollBehavior: to => {
        if (to.hash) {
            return { selector: to.hash };
        } else {
            return { x: 0, y: 0 };
        }
    }
});
