<template>
  <div>
    <md-field v-bind:class="{ 'md-invalid': invalid }">
      <label>Audio file{{ this.file && this.duration ? ': ' + this.duration : '' }}</label>
      <md-file v-model="file" accept="audio/*" @md-change="onSelected" clearable/>
      <span class="md-error">{{ isValid }}</span>
    </md-field>
  </div>
</template>
<script>
import {getDuration} from "../utils/audio-utils";

export default {
  name: 'AudioSelector',
  props: {
    setAudio: {
      type: Function
    },
    isValid: {
      type: String,
      default: null,
    }
  },
  data: () => ({
    file: null,
    error: null,
    duration: null,
  }),
  watch: {
    file(val, oldVal) {
      // Won't trigger on clear field otherwise.
      if (!val) {
        console.log('Has no val, trigger:', val);
        this.onSelected(null);
      }
    }
  },
  methods: {
    async onSelected(event) {
      if (event) {
        let file = event[0];
        getDuration(file).then((seconds) => {
          console.log('got new duration', file, seconds);
          this.duration = this.getStringDuration(seconds);
          this.setAudio(file, seconds);
        }).catch((e) => {
          this.duration = null;
          this.file = null;
          this.error = 'Could not read audio file';
          console.log('Could nog get duration', this.file, this.duration, e);
          this.setAudio(this.file, this.duration);
        });
      } else {
        this.file = null;
        this.duration = null;
        console.log(this.file, this.duration);
        this.setAudio(this.file, this.duration);
      }
    },
    getStringDuration(duration) {
      return new Date(duration * 1000).toISOString().substr(11, 8);
    }
  },
  computed: {
    invalid() {
      if (this.isValid && this.isValid.length > 0) {
        return true;
      } else if (this.error && this.error.length > 0) {
        return true;
      }
      return false;
    }
  }
}
</script>
<style lang="scss" scoped>
.error {
  font-size: 14px;
  color: red;
}
</style>