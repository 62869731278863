<template>
  <div>
    <div class="wrapper">
        <div class="section">
          <div class="container">
            <div v-if="!hasLoaded" class="loader md-layout md-gutter md-alignment-center-center">
              <md-progress-spinner value="center" md-mode="indeterminate"></md-progress-spinner>
            </div>
            <div v-if="hasLoaded" class="container">
              <!-- <SearchBar @send-searchparameter="didChangeSearch" :title="'Våra filer'"
                          :storyspotCount="''"/> -->
              <div class="md-layout md-gutter mt-0">
                <div class="md-layout-item md-size-35 md-small-size-100" style="overflow: hidden">
                  <div v-for="file in getFolders" v-bind:key="file.id" style="margin: 15px;">
                    <tree-menu
                        v-if="file.isFolder && file.directory === '/'"
                        :displayName="file.displayName"
                        :depth="0"
                        :nodes="getFolders.filter((a) =>  a.directory.endsWith(file.name + '/'))"
                        :path="path"
                        :file="file"
                        :folders="getFolders"
                        :onSelect="didChangePath"
                    ></tree-menu>
                  </div>
                </div>
                <div class="md-layout-item md-size-65 md-small-size-100">
                  <md-card class="file-contents">
                    <div class="actionbar">
                      <ul>
                        <li>
                          <md-button v-if="path !== '/'" @click="openUploadModal">
                            <md-icon>upload_file</md-icon>
                            Ladda upp
                          </md-button>
                        </li>
                        <li v-if="path !== '/'">
                          <md-button @click="openCreateFolderModal">
                            <md-icon>create_new_folder</md-icon>
                            Skapa mapp
                          </md-button>
                        </li>
                      </ul>
                    </div>
                    <div v-if="getFiles.length === 0" class="loader md-layout md-gutter md-alignment-center-center">
                      <h5>Mappen är tom</h5>
                    </div>
                    <div v-else v-for="file in getFiles" v-bind:key="file.id" style="margin: 15px;">
                      <FileComponent :file="file" @did-press="didChangePath"/>
                    </div>
                  </md-card>
                </div>
              </div>

            </div>
          </div>
        </div>
    </div>
    <!-- Upload modal -->
    <modal v-if="showUpload" @close="closeUploadModal">
      <template slot="header">
        <md-button
            class="md-simple md-just-icon md-round modal-default-button"
            @click="closeUploadModal"
        >
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot="body">
        <uploadModal :onUpload="uploadFiles"/>
      </template>
    </modal>

    <!-- Create folder modal -->
    <modal v-if="createFolder" @close="closeCreateFolderModal">
      <template v-if="!isLoadingFolder" slot="header">
        <md-button
            class="md-simple md-just-icon md-round modal-default-button"
            @click="closeCreateFolderModal"
        >
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot="body">
        <div v-if="isLoadingFolder" class="loader md-layout md-gutter md-alignment-center-center">
          <md-progress-spinner value="center" md-mode="indeterminate"></md-progress-spinner>
        </div>
        <div v-if="!isLoadingFolder">
          <h3>Skapa ny mapp</h3>
          <br>
          <md-field class="md-form-group" slot="inputs">
            <md-icon>folder</md-icon>
            <label>Namn</label>
            <md-input v-model="folderName" type="text" ref="createFolder" id="createFolder" autofocus required></md-input>
          </md-field>
          <md-button class="md-primary" @click="createNewFolder">Skapa</md-button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import SearchBar from "../../../shared/components/SearchBar";
import FileComponent from "../../../shared/components/FileComponent";
import router from "../router";
import TreeMenu from "../../../shared/components/TreeMenu";
import Modal from "../../../shared/components/Modal";
import uploadModal from "../../../shared/components/Upload.modal";
import sharedConfig from "../../../shared/shared-config";

export default {
  bodyClass: "Our-files",
  components: {
    SearchBar,
    FileComponent,
    TreeMenu,
    Modal,
    uploadModal
  },
  created() {
    document.title = "Resurser - Organization";
  },
  data() {
    return {
      showUpload: false,
      createFolder: false,
      folderName: '',
      files: [],
      path: '/',
      paths: ['/'],
      isLoadingFolder: false,
    }
  },
  mounted() {
    this.getInitialPath(this.$route.params.pathMatch);
    this.$store.dispatch(sharedConfig.store.actions.ADMIN.storage.ADMIN_GET_FILES_REQUEST);
  },
  methods: {
    createNewFolder() {
      this.isLoadingFolder = true;
      this.$store.dispatch(sharedConfig.store.actions.storage.CREATE_FOLDER, {folder: `${this.path}${this.folderName}`}).then(() =>   this.isLoadingFolder = false);
      this.folderName = '';
      this.closeCreateFolderModal();
    },
    uploadFiles(files) {
      this.$store.dispatch(sharedConfig.store.actions.storage.UPLOAD_FILES_REQUEST, {folder: this.path, files});
      this.closeUploadModal();
    },
    closeUploadModal() {
      this.showUpload = false;
    },
    openUploadModal() {
      this.showUpload = true;
    },
    closeCreateFolderModal() {
      this.createFolder = false;
    },
    openCreateFolderModal() {
      this.createFolder = true;
      this.$nextTick(() => document.getElementById('createFolder').focus());
    },
    getInitialPath(path) {
      if (path) {
        this.path = path.replace('/files/', '');
        this.paths = ['/', ...path.replace('/files/', '').split('/')];
        this.didChangePath(path.replace('/files/', ''));
      }
    },
    didChangePath(value) {
      if (this.path && (this.path.endsWith('/') ? this.path.substring(0, this.path.length - 1) : this.path) === value) {
        return;
      }
      if (!value) {
        this.path = '/';
      } else {
        let newPath = '/files/' + (value.startsWith('/') ? value.substring(1) : value);

        if (newPath !== this.$route.path) {
          this.path = value;
          router.push(newPath);
        }
      }

    },
    didChangeSearch(value) {
      this.searchText = value;
    },
  },
  watch: {
    '$route'(to, from) {
      this.path = to.path.replace('/files/', '') === '' ? '/' : to.path.replace('/files/', '');
      this.paths = ['/', ...this.path.split('/')];
    }
  },
  computed: {
    hasLoaded() {
      return this.$store.getters.hasLoadedAllFiles;
    },
    getFolders() {
      let folders = this.$store.getters.getAllFiles.filter((a) => a.isFolder);
      return folders;
    },
    getFiles() {
      return this.$store.getters.getAllFiles.filter((a) => a.directory === this.path).sort((a, b) => b.isFolder - a.isFolder);
    },
  }
};
</script>

<style lang="scss" scoped>

.section {
  // Used cause we want the create storyspot and search header to act as section "headers"
  padding-top: 20px !important;
}

.main {
  min-height: 80vh;
  z-index: 0;
}

.button-container {
  margin-left: auto;
  margin-right: 0;
}

.paths {
  margin-bottom: 20px;
}

.paths li {
  display: block;
  float: left;
  cursor: pointer;
}

.files-label {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}

.file-contents {
  min-height: 60vh;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

li {
  float: left;
}

li a {
  display: block;
  text-align: center;
  padding: 16px;
  text-decoration: none;
  color: black !important;
  font-size: 16px;
}

a .md-icon {
  margin-right: 5px;
}

.actionbar .md-button {
  background-color: transparent !important;
  color: black !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.actionbar .md-button .md-icon {
  color: black !important;
}

</style>
