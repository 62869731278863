<template>
  <footer
      class="footer"
  >
    <div class="container end">
      <nav >
        <ul>
          <li>
            <a href="https://www.storyspot.io">
              <md-icon>home</md-icon>
              storyspot.io
            </a>
          </li>
          <li>
            <strong>• </strong>
          </li>
          <li>
            <router-link to="/faq">
              <md-icon class="nav-icon">quiz</md-icon>
              FAQ
            </router-link>
          </li>
          <li>
            <router-link to="/terms-and-conditions">
              <md-icon class="nav-icon">text_snippet</md-icon>
              Terms of use
            </router-link>
          </li>
          <li>
            <strong>• </strong>
          </li>
          <li>
            <a target="_blank" href="https://instagram.com/storyspot_?igshid=YmMyMTA2M2Y=">
              <md-icon>instagram</md-icon>
              Instagram
            </a>
          </li>
          <li>
            <a target="_blank" href="https://www.facebook.com/storyspot.se/">
              <md-icon>facebook</md-icon>
             Facebook
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style lang="scss" scoped>
strong {
  padding-right: 22px;
  padding-left: 22px;
}
.md-icon .md-icon-font .md-theme-default {
    color:  white !important;
}

</style>
