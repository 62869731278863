<template>
  <div class="wrapper">

      <div class="section">
        <div class="container">
          <div v-if="!hasLoaded" class="loader md-layout md-gutter md-alignment-center-center">
            <md-progress-spinner value="center" md-mode="indeterminate"></md-progress-spinner>
          </div>
          <div v-if="hasLoaded" class="container">
            <SearchBar class="top-padding" @send-searchparameter="didChangeSearch" :title="'All users'"
                       :storyspotCount="'(' + getUsers.length + ')'" :filter="false"/>
            <div class="md-layout">
              <div class="md-layout-item md-size-100 text-center">
                <div v-for="user in pageOfItems">
                  <User :user="user"></User>
                </div>
              </div>
            </div>
            <div class="text-right">
              <jw-pagination :items="getUsers" @changePage="onChangePage" :pageSize="25"
                             :labels="customLabels"></jw-pagination>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
import User from '../../../../shared/components/cells/UserCell'
import SearchBar from '../../../../shared/components/SearchBar'
import BreadCrumbs from "../../../../shared/components/Breadcrumb";
import sharedConfig from "../../../../shared/shared-config";

export default {
  bodyClass: "Org-users",
  components: {
    User,
    BreadCrumbs,
    SearchBar
  },
  data() {
    return {
      searchText: null,
      pageOfItems: [],
      customLabels: {
        first: '<<',
        last: '>>',
        previous: '<',
        next: '>'
      },
      breadcrumbs: [
        {
          to: '/',
          name: "Dashboard"
        },
      ]
    }
  },
  mounted() {
    if (!this.$store.getters.hasLoadedUsers) {
      this.$store.dispatch(sharedConfig.store.actions.ADMIN.GET_ALL_USERS_REQUESTS);
    }
  },
  methods: {
    didChangeSearch(value) {
      this.searchText = value;
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    }
  },
  computed: {
    getUsers() {
      if (this.searchText) {
        return this.$store.getters.getUsers.filter(
            (a) => a.q.includes(this.searchText.toLowerCase())
        )
      }
      return this.$store.getters.getUsers
    },
    hasLoaded() {
      return this.$store.getters.hasLoadedUsers;
    }
  }
}
</script>
<style>
.header {
  margin-bottom: 44px;
}

.main {
  min-height: 80vh;
  z-index: 0;
}

.section {
  padding-top: 20px !important;
}
</style>