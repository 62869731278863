<template>
  <div>
    <div v-if="isLoading" class="loader md-layout md-gutter md-alignment-center-center">
      <md-progress-spinner value="center" md-mode="indeterminate"></md-progress-spinner>
    </div>
    <div v-else-if="hasLoaded" class="md-layout md-gutter">
      <div  class="md-layout-item md-size-20 md-xlarge-size-20 md-large-size-20 md-alignment-center-center">
        <div class="no-profile-pic-container">
          <span  class="dot" :style="`background-color: ${getCategory.color}`"></span>
        </div>
      </div>
      <div class="md-layout-item md-size-70 user-text-container">
        <div class="text-container">

          <md-field required :class="{ 'md-invalid': shouldValidate && getCategory.name.length > 4}">
            <md-icon>title</md-icon>
            <label>Name</label>
            <md-input v-model="getCategory.name" type="text"></md-input>
            <span class="md-error">{{ shouldValidate && getCategory.name.length <= 4 ? 'Name to short' : null }}</span>
          </md-field>
          <md-field required :class="{ 'md-invalid': shouldValidate && getCategory.color.length > 4}">
            <md-icon>color</md-icon>
            <label>Color</label>
            <md-input v-model="getCategory.color" type="text"></md-input>
            <span class="md-error">{{ shouldValidate && getCategory.name.length <= 4 ? 'Name to short' : null }}</span>
          </md-field>
          <md-field required :class="{ 'md-invalid': shouldValidate && isNaN(getCategory.order)}">
            <md-icon>order</md-icon>
            <label>Sort order</label>
            <md-input v-model="getCategory.order" type="text"></md-input>
            <span class="md-error">{{ shouldValidate && isNaN(getCategory.order) ? 'Needs to be valid number' : null }}</span>
          </md-field>
      </div>
        <div class="md-layout-item md-size-100 m-top text-center">
          <md-button class="md-primary md-lg" @click="update" :disabled="!canUpdate">Update</md-button>
        </div>
    </div>
  </div>
  </div>
</template>
<script>
export default {
  bodyClass: "edit-category",
  data() {
    return {
      isEdit: false,
      shouldValidate: false,
      original: null,
      category: {
        name: null,
        sort: null,
        color: null,
        status: {
          name: null,
        }
      },
    }
  },
  mounted() {
  },
  methods: {
    canUpdate() {
      return false;
    },
    update() {

    },
    toggleEdit() {
      this.edit = !this.edit;
      if (this.isEdit) {
        this.category = this.$store.getters.getCategories.filter((a) => a.id === this.$route.params.id)[0];
      }
    }
  },
  computed: {
    getCategory() {
      if (this.isEdit) {
        return this.category
      }
      if (this.original === null) {
        this.original = this.$store.getters.getCategories.filter((a) => a.id === this.$route.params.id)[0];
      }
      return this.$store.getters.getCategories.filter((a) => a.id === this.$route.params.id)[0];
    },
    isLoading() {
      return this.$store.getters.getStaticIsLoading;
    },
    hasLoaded() {
      return this.$store.getters.getStaticHasLoaded;
    }
  }
}
</script>
<style lang="scss" scoped>
.no-profile-pic-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.no-profile-pic {
  font-size: 68px !important;
  opacity: 0.5;
}
.dot {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  display: inline-block;
}
</style>