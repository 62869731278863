const COLLECTION_TYPE = {
    storyspot: 'storyspot',
    collection: 'collection',
    singleWideStoryspots: 'singleWideStoryspots',
    singleStoryspot: 'singleStoryspot',
};

export class ThemeCollection {
    constructor(jsonMap, { col, sps, sp } = {}) {
        try {
            this.id = jsonMap['id'];
            this.sort = jsonMap['sort'] || '';
            this.title = jsonMap['title'] || '';
            this.inactive = jsonMap['inactive'] || false;
            this.startDate = jsonMap['startDate']
            this.endDate = jsonMap['endDate'];

            if (jsonMap['description'] != null && jsonMap['image'] != null) {
                this.type = COLLECTION_TYPE.singleWideStoryspots;
                this.image = jsonMap['image'];
                this.description = jsonMap['description'];
                this._list = sps;
            } else if (sp != null) {
                this.storyspot = sp;
                this.type = COLLECTION_TYPE.singleStoryspot;
            } else if (col != null) {
                this.type = COLLECTION_TYPE.collection;
                this._list = col;
            } else if (sps != null) {
                this.type = COLLECTION_TYPE.storyspot;
                this._list = sps;
            } else {
                this._list = [];
            }
        } catch (e) {
            return;
        }
    }

    toJson() {
        return {
            'id': this.id,
            'sort': this.sort.toString(),
            'type': this.type,
            'title': this.title,
            'list': this._list?.map((a) => a),
            'storyspot': this.storyspot
        };
    }

    get list() {
        if (this._list != null && this._list.length > 0) {
            return this._list.filter((element) => element != null);
        } else {
            return [];
        }
    }

    get isActive() {
        let date = new Date();
        if (this.inactive) {
            return false;
        } else if (this.endDate && new Date(this.endDate) < date) {
            return false;
        } else if (this.startDate && new Date(this.startDate) > date) {
            return false;
        }
        return true;
    }
}

export class ThemeItem {
    constructor(jsonMap, sp) {
        this.title = jsonMap['title'];
        this.image = jsonMap['image'];
        this.storyspots = sp;
    }

    toJson() {
        return {
            'title': this.title,
            'image': this.image,
            'storyspots': this.storyspots.map((a) => a.toJson()),
        };
    }
}