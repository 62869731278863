<template>

  <div class="section">
    <div v-if="!hasLoaded" class="loader md-layout md-gutter md-alignment-center-center">
      <md-progress-spinner value="center" md-mode="indeterminate"></md-progress-spinner>
    </div>
    <div v-if="hasLoaded" class="container">
      <md-switch v-model="showOnlyActive" class="md-primary">Only show active</md-switch>
      <div class="button-container">
        <router-link to="/recommendations/discover/create">
          <md-button class="md-primary btn-regular">
            <md-icon>add</md-icon>
            Create Discover
          </md-button>
        </router-link>
      </div>
      <div class="md-layout-item md-small-size-100 md-medium-size-50">
        <h1>Discover <strong>{{ '(' + getDiscovers.length + ')' }}</strong></h1>
      </div>
      <div class="md-layout">
        <div class="md-layout-item md-size-100 text-center">
          <div v-for="disc in pageOfItems">
            <md-card style="padding: 22px">
              <DiscoverCell :discover="disc"/>
            </md-card>
          </div>
        </div>
      </div>
      <div class="text-right">
        <jw-pagination :items="getDiscovers" @changePage="onChangePage" :pageSize="25"
                       :labels="customLabels"></jw-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import SearchBar from '../../../../shared/components/SearchBar'
import BreadCrumbs from "../../../../shared/components/Breadcrumb";
import DiscoverCell from "../../components/cells/DiscoverCellV2";

export default {
  bodyClass: "discovers",
  components: {
    BreadCrumbs,
    SearchBar,
    DiscoverCell
  },
  created() {
    document.title = 'Discover - Storyspot';
  },
  data() {
    return {
      searchText: null,
      pageOfItems: [],
      showOnlyActive: true,
      customLabels: {
        first: '<<',
        last: '>>',
        previous: '<',
        next: '>'
      },
      breadcrumbs: [
        {
          to: '/',
          name: "Dashboard"
        },
      ]
    }
  },
  mounted() {
  },
  methods: {
    /*   didChangeSearch(value) {
         this.searchText = value;
       }, */
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    }
  },
  computed: {
    getDiscovers() {
      return this.$store.getters.getDiscover.filter((a) => this.showOnlyActive ? a.isActive : true);
    },
    hasLoaded() {
      return this.$store.getters.hasLoadedDiscover;
    }
  }
}
</script>
<style lang="scss" scoped>
.header {
  margin-bottom: 44px;
}

.main {
  background-color: #EAEAEA !important;
  min-height: 80vh;
  z-index: 0;
}

.section {
  padding-top: 20px !important;
}

.button-container {
  margin-left: auto;
  margin-right: 0;
}
.md-card {
  margin-bottom: 8px;
  margin-top: 8px;
}
</style>