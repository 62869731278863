<template>
  <div>
    <div class="text-container">
      <div class="heading-container">
        <router-link :to="'/recommendations/discover/edit/' + discover.id">
          <h3 v-if="discover.type !== 'singleStoryspot'" class="text-left">{{ discover.title }}</h3>
         <h3 v-if="discover.type === 'singleStoryspot'" class="text-left">Rekommendation</h3>
        </router-link>
        <badge :style="`background-color: ${discover.isActive ? 'green' : 'red'}`">
            {{ discover.isActive ? 'Active' : 'Inactive' }}
        </badge>
      </div>
    </div>
    <div class="scrollmenu text-left">
      <a v-if="discover.type !== 'collection'" v-for="spot in discover.list">
        <div class="scrollmenu-div">
          <div class="image-container">
            <img :src="spot.displayImage" alt="Rounded Image" class="displayImage scroll-img"/>
            <div class="info">
              <md-icon class="icon">play_arrow</md-icon>
              <p class="short-text">{{ spot.durationTime }}</p>
            </div>
          </div>
          <p class="scrollmenu-text">{{ spot.name }}</p>
        </div>
      </a>
      <a v-if="discover.type === 'collection'" v-for="themeItem in discover.list">
        <div class="scrollmenu-div">
          <img :src="themeItem.image" alt="Rounded Image" class="scroll-img"/>
          <p class="scrollmenu-text">{{ themeItem.title }}</p>
        </div>
      </a>
      <div v-if="discover.type === 'singleStoryspot'">
        <StoryspotCellSmall :storyspot="discover.storyspot" :toSpotBasePath="'/storyspots/'"/>
      </div>
    </div>
  </div>
</template>
<script>
import StoryspotDiscover from "../StoryspotDiscover";
import StoryspotCellSmall from "../../../../shared/components/cells/StoryspotCellSmall";
import {Badge} from "../../../../shared/components";


export default {
  components: {
    StoryspotDiscover,
    StoryspotCellSmall,
    Badge
  },
  props: {
    discover: {
      type: Object,
      default: null
    },
  },
  data() {
    return {}
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.scrollmenu {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
}

scrollmenu a {
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

.scrollmenu-div {
  flex: 0 0 180px; /* Set a fixed width for each item */
  display: flex; /* Use flexbox for vertical alignment of image and text */
  flex-direction: column; /* Stack image and text vertically */
  align-items: center; /* Center items horizontally */
  margin-right: 10px; /* Add some spacing between items */
  flex-wrap: nowrap; /* Prevent flex items from wrapping to the next line */
  position: relative;
}

.scrollmenu-text {
  width: 162px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.scroll-img {
  margin-left: 0 !important;
  height: 150px;
  width: 150px;
  object-fit: cover;
  border-radius: 8px;
}

.image-container {
  position: relative; /* Set the container as a reference for absolute positioning */
}

.icon {
  width: 20px;
  height: 20px;
  background-color: white; /* Set the background color or use an image for the icon */
  margin-right: 5px;
  position: absolute;
  bottom: 5px; /* Adjust the distance from the bottom as needed */
  left: 5px; /* Adjust the distance from the left as needed */
  border-radius: 10px;
}

.info {
  position: absolute; /* Position the .info div absolutely */
  bottom: 5px; /* Adjust the distance from the bottom as needed */
  left: 5px; /* Adjust the distance from the left as needed */
  display: flex; /* Use flexbox to align icon and text horizontally */
  align-items: center; /* Center icon and text vertically */

}

.short-text {
  margin: 0; /* Remove default margin */
  white-space: nowrap; /* Prevent the text from wrapping */
  overflow: hidden;
  text-overflow: ellipsis; /* Add ellipsis if the text overflows */
  position: absolute;
  bottom: 5px; /* Adjust the distance from the bottom as needed */
  left: 35px; /* Adjust the distance from the left as needed */
  max-width: 120px;
  color: white;
  font-weight: bold;
}

.text-container {
  display: flex; /* Use flexbox to align children horizontally */
  align-items: center; /* Vertically center the children (h3 and badge) */
  margin-top: 0;
  padding: 0;
}

.heading-container {
  display: flex; /* Use flexbox to align h3 and badge horizontally */
  align-items: center; /* Vertically center h3 and badge */
}
.heading-container .badge {
  margin-left: 8px;
}
</style>