<template>
  <div>
    <md-field class="md-form-group">
      <md-icon>search</md-icon>
      <md-input
          v-model="searchText"
          placeholder="Search..."
      ></md-input>
    </md-field>
    <div>
      <div class="user-container" v-for="spot in getStoryspots" v-bind:key="spot.id">
        <div>
          <md-checkbox class="checkbox" v-model="selectedStoryspots" :value="spot">
            <!--<StoryspotCellSmall style="margin-top: 0px; !important; padding-top: 0px !important;" :storyspot="spot"/> -->
            <div class="checkbox-line">
              <img class="mini-image" :src="spot.displayImagePlaceholder">
              <p>{{spot.name}}</p>
            </div>
          </md-checkbox>
        </div>

      </div>
    </div>
    <div v-if="selectedStoryspots.length > 0">
      <p class="small-paragraph text-left">Selected storyspots</p>
      <hr>
      <div>
        <div class="user-container" v-for="spot in selectedStoryspots" v-bind:key="spot.id">
          <div>
            <md-checkbox v-model="selectedStoryspots" :value="spot">
              <div class="checkbox-line">
                <img class="mini-image" :src="spot.displayImagePlaceholder">
                <p>{{spot.name}}</p>
              </div>
            </md-checkbox>
          </div>
        </div>
      </div>
      <div class="add-user-container">
        <md-button class="md-primary btn-regular" @click="addStoryspots">
          <md-icon>add</md-icon>
          Add
        </md-button>
      </div>
    </div>
  </div>
</template>
<script>

import StoryspotCellSmall from "./cells/StoryspotCellSmall";
export default {
  components: {StoryspotCellSmall},
  props: {
    close: {type: Function}
  },
  data() {
    return {
      searchText: null,
      selectedStoryspots: [],
    };
  },
  methods: {
    addStoryspots() {
      this.$emit('add-storyspots', this.selectedStoryspots);
      this.close();
    },
  },
  computed: {
    getStoryspots() {
      if (this.searchText) {
        return this.$store.getters.getStoryspots.filter(
            (a) =>
                a.q && a.q.indexOf(this.searchText.toLowerCase()) > -1
        ).slice(0,8);
      }
      return [];
    }
  }
};
</script>
<style scoped>
.small-paragraph {
  margin-top: 26px;
}
.user-container {
  margin-top:auto;
  margin-bottom:auto;
  text-align:left;
  max-height: 400px !important;
}

.add-user-container {
  margin-left: auto;
  margin-right: 0;
}

.checkbox-line img {
  float:left;
}
.checkbox-line  p {
  display:inline;
  margin-left:10px;
}

.mini-image {
  width: 25px;
  height: 25px;
  object-fit: contain;
}
</style>