import baseAPi from "./base-api-service";
import sharedConfig from "../shared-config";

export default {
    //
    //
    // Login
    getOrganizationAdminFiles: async function () {
        return await baseAPi.getData(sharedConfig.api.organizationfiles.adminFiles);
    },
    getOrganizationFiles: async function () {
        return await baseAPi.getData(sharedConfig.api.organizationfiles.files);
    },
    uploadOrganizationFiles: async function (formData) {
        return await baseAPi.postFormData(sharedConfig.api.organizationfiles.files, formData);
    },
    createOrganizationFolder: async function (folder) {
        return await baseAPi.postData(sharedConfig.api.organizationfiles.files + '/folder', {folder});
    },
}