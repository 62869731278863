import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

import auth from "./auth";
import createStoryspot from "./createStoryspot";
import editStoryspot from "./editStoryspot";
import storyspots from "./storyspot";
import organizations from "./organizations";
import currentState from "./currentState";
import reports from "./reports";
import users from "./users";
import stats from "./stats";
import player from "../../../shared/store/player-store";
import staticStore from "../../../shared/store/static-store";
import recommendations from "./recommendations";
import admin from "./admin";
import adminStorage from "./adminStorageState";
import storage from "../../../shared/store/storage-store";

export default new Vuex.Store({
    modules: {
        currentState,
        auth,
        storyspots,
        users,
        organizations,
        reports,
        player,
        staticStore,
        createStoryspot,
        editStoryspot,
        stats,
        recommendations,
        admin,
        adminStorage,
        storage
    }
});
