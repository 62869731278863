import sharedConfig from "../../../shared/shared-config";
import organizationApi from "../../../shared/services/file-service";

const state = {
    files: [],
    hasLoadedFiles: false,
    isLoading: false,
}

const getters = {
    getAllFiles(state) {
        return state.files;
    },
    hasLoadedAllFiles(state) {
        return state.files;
    },
}

const actions = {
    [sharedConfig.store.actions.ADMIN.storage.ADMIN_GET_FILES_REQUEST]: ({commit}) => {
        organizationApi.getOrganizationAdminFiles().then((response) => {
            commit(sharedConfig.store.actions.ADMIN.storage.ADMIN_GET_FILES_SUCCESS, response)
        });
    },
}

const mutations = {
    [sharedConfig.store.actions.ADMIN.storage.ADMIN_GET_FILES_SUCCESS]: (state, response) => {
        state.files = response.data;
        state.isLoading = false;
        state.hasLoadedFiles = true;
    },
    [sharedConfig.store.actions.ADMIN.storage.ADMIN_GET_FILES_ERROR]: (state, audio) => {
        state.files = [];
        state.isLoading = false;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
};