<template>
  <div>
    <md-card class="main-div">
      <div class="md-layout md-gutter">
        <div
            class="md-layout-item md-size-20 md-xlarge-size-20 md-large-size-20"
        >
          <img
              v-if="organization.profile.picture"
              :src="organization.profile.picture"
              alt="Rounded Image"
              class="displayImage"
          />
          <div v-else class="no-profile-pic-container">
            <md-icon class="no-profile-pic">face</md-icon>
          </div>
        </div>
        <div
            class="md-layout-item md-size-70 cell-content"
        >
          <div class="text-container text-left">
              <div class="blockquote large-paragraph text-left">
                <small>
                  Användare: {{organization.users.length}} <strong> • </strong> {{ organization.profile.currentDateString }}
                </small>
            </div>
            <h4 class="title text-left">
              <router-link :to="'/organizations/' + organization.id">
                <span class="tim-note">{{ organization.profile.displayName }}</span>
              </router-link>
            </h4>
            <p class="text-description">
              {{ organization.profile.description }}
            </p>
          </div>
        </div>
      </div>
    </md-card>
  </div>
</template>
<script>
import Badge from "../Badge";
import Vue from "vue";
import QrCode from "../QrCode";
import sharedConfig from "../../shared-config"
import {copyString} from "../../utils/general-utils";

export default {
  components: {
    QrCode,
    Badge
  },
  props: {
    organization: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      searchInput: null,
      showQrModal: false,
    }
  },
  mounted() {

  },
  methods: {
    showModal() {
      this.showQrModal = true;
    },
    closeModal() {
      this.showQrModal = false;
    },
    copyLink() {
      let link = "https://app.storyspot.se/share/a" + this.organization.id;
      copyString(link);
      const instance = Vue.$toast.open({
        message: "Link copied successfully",
        type: "success",
        position: "top-right"
      });
    },
  }
};
</script>
<style lang="scss" scoped>
.md-card {
  margin: 12px 0 12px 0;
  max-height: 150px;
}
.cell-content {
  margin: 30px 0 30px;
}

.blockquote {
  font-size: 18px;
}

.blockquote strong {
  margin: 0 5px 0 5px;
}

.text-container {
  float: left;
  margin-top: 0;
  padding: 0;
}

.text-container .p {
  text-overflow: ellipsis;
}

.title {
  margin-top: 0 !important;
  margin-bottom: 5px !important;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;
  color: #3C4858 !important;
}

.md-theme-default a:not(.md-button) {
  color: unset !important;
}


.center {
  margin: auto;
  width: 50%;
}

.icon {
  font-size: 18px !important;
}

.text-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.play-icon {
  position: absolute !important;
  right: 30px;
}


.dropdown-item:hover {
  .md-icon {
    color: white !important;
  }

  p {
    color: white !important;
  }
}

.md-tooltip {
  border-radius: unset;
  color: white !important;
  background-color: #989898 !important;
  font-weight: unset;
  box-shadow: unset;
  max-width: unset;
}

.not-active {
  float: left;
  padding-right: 8px
}

.displayImage {
  margin-left: 0 !important;
  // Just to fill width
  max-height: 150px;
  min-height: 150px;
  border-radius: 6px 0 0 6px;
  object-fit: cover;
}

.no-profile-pic-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.no-profile-pic {
  font-size: 68px !important;
  opacity: 0.5;
}

.md-list-item a .material-icons, .md-list-item a .md-icon, .md-list-item a .fab, .md-list-item a .fa {
  font-size: 25px !important;
}

.mini-list {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;

  p {
    padding-top: 10px;
  }
}

.text-description {
  white-space: nowrap;
  text-overflow: ellipsis ellipsis;
  max-width: 600px;
}

</style>