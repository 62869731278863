<template>
  <div>
    <div class="main-content">
      <div class="wrapper">

        <div class="main main-raised">

          <div class="section">
            <div v-if="loading" class="loader md-layout md-gutter md-alignment-center-center">
              <md-progress-spinner value="center" md-mode="indeterminate"></md-progress-spinner>
            </div>
            <div v-if="!loading" class="container">
              <div class="header">
                <h1>
                  Organization
                </h1>
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-size-50 md-small-size-100 md-small-size-100 md-medium-size-50">
                  <SearchUserSelectorTextField @selected-user="selectedUser"/>

                </div>
                <div class="md-layout-item md-size-50 md-small-size-100 md-small-size-100 md-medium-size-50">
                  <Autocomplete  :options="subscriptions" label="Subscription" icon="loyalty" :selected="changeSubscription"/>
                </div>
              </div>
              <div class="md-layout-item md-size-100 m-top text-center">
                <md-button class="md-primary md-lg" @click="create" :disabled="!isValid">Create</md-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Autocomplete from "../../../../shared/components/Autocomplete";
import SearchUserSelectorTextField from "../../../../shared/components/SearchUserSelectorTextField";
import sharedConfig from "../../../../shared/shared-config";
import router from "../../router";

export default {
  bodyClass: "org-create",
  components: {
    Autocomplete,
    SearchUserSelectorTextField
  },
  created() {
    document.title = 'Create organization - Storyspot';
  },
  data() {
    return {
      loading: false,
      showAddUserModal: false,
      org: {
        user: null,
        subscription: null,
      }
    }
  },
  mounted() {
  },
  methods: {
    create() {
      this.$store.dispatch(sharedConfig.store.actions.ORGANIZATION.CREATE_ORGANIZATION_REQUEST, {
        userId: this.org.user.id,
        subscriptionId: this.org.subscription._id,
      }).then((org) => {
        router.push("/organizations/" + org.id);
      })
    },
    selectedUser(user) {
      this.org.user = user;
    },
    addUserModalShow() {
      this.showAddUserModal = true;
    },
    addUserModalClose() {
      this.showAddUserModal = false;
    },
    changeSubscription(value) {
      console.log('value', value);
      this.org.subscription = value;
    },
  },
  computed: {
    isValid() {
      return this.org.user && this.org.subscription;
    },
    subscriptions() {
      return this.$store.getters.getStaticHasLoaded ? this.$store.getters.getSubscriptions : [];
    },
  }
}
</script>
<style>
.header {
  margin-bottom: 44px;
}

.main {
  min-height: 80vh;
  z-index: 0;
}

.section {
  padding-top: 20px !important;
}

.button-container {
  margin-left: auto;
  margin-right: 0;
}
</style>